.tableView {
  position: relative;
}

.paginationSearchInput {
  width: auto;
  position: absolute;
  right: 132px;
  top: -2px;
  height: 36px;
  z-index: 4;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.paginationSearchInput input {
  width: auto;
  padding: 0;
  box-shadow: none !important;
  border: none;
}

.paginationSearchInput .input-group-prepend {
  width: 40px;
  border-right: 1px solid #e6e6e6;
}

.paginationSearchInput .input-group-text {
  position: absolute;
  height: 100%;
  z-index: 4;
  padding: 0px;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.searchOnlyBlock .paginationSearchInput {
  position: relative;
  right: unset;
}

.actionBtn {
  padding: 6px 10px;
  font-size: 14px;
}

.actionBtn::after {
  display: none;
}

.searchOnlyBlock .paginationSearchInput {
  position: relative;
  right: unset;
}

.led-green {
  width: 12px;
  height: 12px;
  background-color: #a1ee02;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px,
    #8cff07 0 0px 9px;
  margin: 9px;
}

.led-red {
  width: 12px;
  height: 12px;
  background-color: #ee0202;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px,
    #ff0707 0 0px 9px;
  margin: 9px;
}

.cursor-pointer {
  cursor: pointer;
}

.deletedBtn {
  /* position: absolute;
  top: 18px;
  right: 20px;
  z-index: 10; */
}

.deletedBtn label {
  margin-bottom: 0;
}

.deletedBtn input {
  margin-right: 10px;
}

.winner-percentage-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.winner-percentage-row div {
  flex: 1;
  height: auto;
  padding: 0px 5px;
}

.select-style {
  width: 100%;
  height: 35px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.dropdown-menu li {
  color: #070606;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
  transition: 0.3s ease;
}

.dropdown-menu li:hover {
  background: #5169dc;
  color: white;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li a {
  display: block;
}

.dropdown-menu {
  background: #fffefe;
  padding: 0px;
}

.dropdown-menu li i {
  margin-right: 5px;
}

.tamplatelist.table> :not(caption)>*>* {
  padding: 0.4rem 0.75rem;
  font-size: 12px;
}

.playing_table.table>:not(caption)>*>* {
  padding: 0.2rem 0.75rem;
}

:focus-visible {
  outline: none !important;
}

.login-modal::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #666666;
  filter: blur(1px);
  z-index: -1;
}

.ck-editor__editable {
  min-height: 420px;
  /* Set the minimum height as needed */
  max-height: 420px;
}
.history-custom-btn{
  position: absolute;
  z-index: 2;
}